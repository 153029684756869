/**
 * Styles for error pages only
 * @see templates/bundles/TwigBundle/Exception/
 */

// FontAwesome
@import "../font-awesome/scss/fontawesome";
@import "../font-awesome/scss/brands";
@import "../font-awesome/scss/regular";
@import "../font-awesome/scss/solid";



body{
	padding: 30px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	background: #eaeaea;
	line-height: 1.3em;
}
.square{
	margin:auto;
	padding: 30px;
	background:white;
	max-width: 500px;
	border-radius: 4px;
	box-shadow: 0 1px 3px rgba(0,0,0,.1);
}
a{
	color: #5abcd3;
	text-decoration: none;
}
a:hover, a:focus{
	color: #41a1b7;
}

hr{
	border:none;
	border-bottom: 1px solid #eaeaea;
	margin: 30px 0
}

.note, .code{
	font-size: 12px;
	line-height: 16px;
	color:gray;
}

.code{
	font-family: Courier, monospace;
}
